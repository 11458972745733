/*
    MAKE SURE YOU EDIT `/config/ts/tailwind.ts` RATHER THAN `/config/tailwind.js` WHICH IS AUTOMATICALLY
    COMPILED FROM THE TYPESCRIPT
    ___
    This is a file of config fragments that we can easily import into files without loading in
    the entire Tailwind config. These are added in tailwind.config.js, and thus they're written
    as Common Modules rather than ES6 modules. That doesn't stop them being importable by
    React components, though.
*/

const fontSize = {
    xs: '0.75rem',
    sm: '0.875rem',
    base: '1rem',
    md: '1.125rem',
    lg: '1.25rem',
    xl: '1.5rem',
    '2xl': '1.875rem',
    '3xl': '1.875rem',
    '4xl': '2.25rem',
    '5xl': '2.75rem',
    '6xl': '4.5rem',
    '7xl': '5.3125rem',
} as const

const screens = {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    '2xl': '1536px',
} as const

const maxWidth = {
    xs: '600px',
    sm: '900px',
    md: '1140px',
    lg: '1280px',
} as const

const colors = {
    transparent: 'transparent',
    current: 'currentColor',
    white: '#ffffff',
    grey: '#f8f8f8',
    black: '#000000',
    purple: '#7730ED',
    blue: '#002CFF',
    aqua: '#80E5EC',
    pink: '#FF80C3',
    'light-pink': '#DA90FF',
    yellow: '#FFEB38',
    'light-grey': '#F2F2F2',
    red: '#FF0000',
} as const

const colourMapping: { [key: string]: string } = {
    black: 'bg-black',
    white: 'bg-white',
    aqua: 'bg-aqua',
    purple: 'bg-purple',
    pink: 'bg-pink',
    lightPink: 'bg-light-pink',
    yellow: 'bg-yellow',
} as const

const fillMapping: { [key: string]: string } = {
    black: 'fill-black',
    white: 'fill-white',
    aqua: 'fill-aqua',
    purple: 'fill-purple',
    pink: 'fill-pink',
    lightPink: 'fill-light-pink',
    yellow: 'fill-yellow',
} as const

const maxWidthMapping: { [key: string]: string } = {
    xs: 'max-w-xs',
    sm: 'max-w-sm',
    md: 'max-w-md',
    lg: 'max-w-lg',
} as const

const buttonTransitions: { [key: string]: string } = {
    black: 'text-black hover:bg-black hover:border-black hover:text-white transition duration-500 ease-in-out',
    white: 'text-white hover:bg-white hover:border-white hover:text-black transition duration-500 ease-in-out',
    yellow: 'text-black bg-yellow border-yellow hover:bg-transparent hover:border-yellow hover:text-yellow transition duration-500 ease-in-out',
    tabActive:
        'text-white bg-purple border-purple cursor-default transition duration-500 ease-in-out',
    tabInactive:
        'text-black bg-transparent border-black border-opacity-20 hover:bg-transparent hover:border-purple hover:text-purple transition duration-500 ease-in-out',
} as const

/* Maps colour to background / theme */
const buttonMapping: { [key: string]: string } = {
    white: buttonTransitions['black'],
    aqua: buttonTransitions['black'],
    purple: buttonTransitions['white'],
    pink: buttonTransitions['black'],
    lightPink: buttonTransitions['black'],
    yellow: buttonTransitions['black'],
    black: buttonTransitions['white'],
    footer: buttonTransitions['yellow'],
    tabActive: buttonTransitions['tabActive'],
    tabInactive: buttonTransitions['tabInactive'],
} as const

const paddingMarginSafelist: { [key: string]: string } = {
    'xs-py': `py-[100px] lg:py-10`,
    'sm-py': `py-[100px] md:py-[120px] lg:py-24`,
    'md-py': `py-[100px] md:py-[7.5rem]`,
    'lg-py': `py-[100px] md:py-[150px]`,
    'xs-my': `my-[100px] lg:my-10`,
    'sm-my': `my-[100px] md:my-[120px] lg:my-24`,
    'md-my': `my-[100px] md:my-[7.5rem]`,
    'lg-my': `my-[100px] md:my-[150px]`,
} as const

// Typescript Typeshttp://localhost:3005/articles/test-article
export type ScreenSizeHandleType = keyof typeof screens
export type ScreenSizeType = typeof screens[ScreenSizeHandleType]

export type MaxWidthHandleType = keyof typeof maxWidth
export type MaxWidthType = typeof maxWidth[MaxWidthHandleType]

export type ColorsHandleType = keyof typeof colors
export type ColorsType = typeof colors[ColorsHandleType]

export type FontSizeHandleType = keyof typeof fontSize
export type FontSizeType = typeof fontSize[FontSizeHandleType]

export {
    screens,
    maxWidth,
    colors,
    fontSize,
    colourMapping,
    fillMapping,
    maxWidthMapping,
    buttonMapping,
}
