import facebook from '@/svg/facebook'
import twitter from '@/svg/twitter'
import linkedin from '@/svg/linkedin'
import youtube from '@/svg/youtube'

const socialLinks: { name: string; icon: JSX.Element; url: string; shareUrl?: string }[] = [
    {
        name: 'Facebook',
        icon: facebook(),
        url: 'https://www.facebook.com/weareoko',
        shareUrl: `https://www.facebook.com/sharer/sharer.php?u=`,
    },
    {
        name: 'Twitter',
        icon: twitter(),
        url: 'https://twitter.com/weareoko',
        shareUrl: 'http://twitter.com/share?url=',
    },
    {
        name: 'Linkedin',
        icon: linkedin(),
        url: 'https://www.linkedin.com/company/weareoko/',
        shareUrl: 'https://www.linkedin.com/sharing/share-offsite/?url=',
    },
    {
        name: 'Youtube',
        icon: youtube(),
        url: 'https://www.youtube.com/channel/UCMBfvt6OY41Q0w-8lGZjItQ',
    },
]

export default socialLinks
