function youtubeIcon() {
    return (
        <svg
            width="16"
            height="12"
            viewBox="0 0 16 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.2753 2.68944C15.0819 1.44077 14.5018 0.576308 13.1483 0.384205C11.0214 8.58765e-08 7.73432 0 7.73432 0C7.73432 0 4.44723 8.58765e-08 2.3203 0.384205C0.96679 0.576308 0.290037 1.44077 0.193358 2.68944C0 3.9381 0 5.76308 0 5.76308C0 5.76308 0 7.58805 0.193358 8.83672C0.386716 10.0854 0.96679 10.9498 2.3203 11.1419C4.44723 11.5262 7.73432 11.5262 7.73432 11.5262C7.73432 11.5262 11.0214 11.5262 13.1483 11.1419C14.5018 10.8538 15.0819 10.0854 15.2753 8.83672C15.4686 7.58805 15.4686 5.76308 15.4686 5.76308C15.4686 5.76308 15.4686 3.9381 15.2753 2.68944ZM5.80074 8.64461V2.88154L10.6347 5.76308L5.80074 8.64461Z"
                className="fill-current"
            />
        </svg>
    )
}

export default youtubeIcon
