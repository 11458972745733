import { motion } from 'framer-motion'
import { fadeIn } from '@/lib/utils/framer-motion-config'

function playStoreIcon(classes?: string, colour = 'white', background = 'none') {
    return (
        <motion.div {...fadeIn}>
            <svg
                width="123"
                height="41"
                viewBox="0 0 123 41"
                fill={background}
                xmlns="http://www.w3.org/2000/svg"
                className={classes}
            >
                <path
                    d="M122.071 34.7339C122.071 37.4577 119.873 39.6612 117.165 39.6612H5.69313C2.9851 39.6612 0.787598 37.4577 0.787598 34.7339V5.42732C0.787598 2.70346 2.9851 0.5 5.69313 0.5H117.165C119.871 0.5 122.071 2.7036 122.071 5.42732V34.7339Z"
                    stroke={colour}
                />
                <path
                    d="M9.4585 7.73242V21.3331V34.9314L23.1194 21.3331L9.4585 7.73242Z"
                    fill="url(#paint0_linear_2085_4426)"
                />
                <path
                    d="M26.7868 17.6805L21.2737 14.4831L9.4585 7.63477V7.73318L23.1194 21.3339L26.7868 17.6805Z"
                    fill="url(#paint1_linear_2085_4426)"
                />
                <path
                    d="M9.4585 34.9303V35.0287L21.2737 28.1804L26.7868 24.983L23.1194 21.332L9.4585 34.9303Z"
                    fill="url(#paint2_linear_2085_4426)"
                />
                <path
                    d="M26.7866 17.6787L23.1191 21.3321L26.7866 24.9831L33.0887 21.3321L26.7866 17.6787Z"
                    fill="url(#paint3_linear_2085_4426)"
                />
                <path
                    d="M95.4546 26.6486C94.9405 26.6486 94.4743 26.5525 94.0488 26.3629C93.6256 26.1733 93.2694 25.8804 92.9801 25.4868H92.894C92.9514 25.9476 92.9801 26.3869 92.9801 26.7998V30.0547H91.7871V18.5952H92.7578L92.9227 19.6778H92.9801C93.2861 19.2457 93.6447 18.9312 94.0512 18.7392C94.4576 18.5472 94.9262 18.4512 95.4522 18.4512C96.4969 18.4512 97.3026 18.8088 97.8716 19.5266C98.4406 20.2443 98.7227 21.25 98.7227 22.5439C98.7227 23.8425 98.4335 24.8531 97.8573 25.5708C97.2811 26.2909 96.4802 26.6486 95.4546 26.6486ZM95.2824 19.4689C94.4767 19.4689 93.8958 19.6922 93.5372 20.1411C93.1785 20.5875 92.9921 21.3005 92.9825 22.2774V22.5439C92.9825 23.6552 93.1666 24.4498 93.5372 24.9299C93.9053 25.4075 94.4982 25.6476 95.3111 25.6476C95.9925 25.6476 96.5256 25.3715 96.9105 24.817C97.2954 24.265 97.4891 23.5016 97.4891 22.5295C97.4891 21.5429 97.2954 20.7868 96.9105 20.2587C96.5232 19.733 95.9805 19.4689 95.2824 19.4689Z"
                    fill={colour}
                />
                <path d="M101.486 26.5045H100.293V15.2754H101.486V26.5045Z" fill={colour} />
                <path
                    d="M108.348 26.5034L108.112 25.3776H108.054C107.662 25.8744 107.27 26.2081 106.878 26.3833C106.488 26.5586 106.001 26.6474 105.415 26.6474C104.633 26.6474 104.021 26.4457 103.579 26.0401C103.136 25.6368 102.914 25.0607 102.914 24.3166C102.914 22.7203 104.186 21.8826 106.73 21.8058L108.066 21.7625V21.2729C108.066 20.6512 107.932 20.1927 107.667 19.8974C107.402 19.6022 106.976 19.4534 106.39 19.4534C105.733 19.4534 104.992 19.655 104.162 20.0607L103.796 19.1437C104.184 18.9325 104.609 18.7668 105.073 18.6468C105.534 18.5268 105.998 18.4668 106.464 18.4668C107.404 18.4668 108.1 18.6756 108.551 19.0957C109.003 19.5134 109.23 20.1855 109.23 21.1096V26.5082H108.348V26.5034ZM105.651 25.6608C106.395 25.6608 106.976 25.4568 107.402 25.0487C107.825 24.6406 108.037 24.0669 108.037 23.33V22.6147L106.844 22.6651C105.895 22.6987 105.212 22.8475 104.793 23.1092C104.375 23.3708 104.164 23.7789 104.164 24.3334C104.164 24.7655 104.294 25.0967 104.557 25.3224C104.817 25.5456 105.183 25.6608 105.651 25.6608Z"
                    fill={colour}
                />
                <path
                    d="M109.923 18.5938H111.202L112.929 23.1041C113.306 24.1339 113.543 24.878 113.634 25.3341H113.691C113.753 25.0892 113.885 24.6692 114.083 24.0739C114.282 23.4786 114.932 21.6543 116.034 18.5938H117.313L113.928 27.6001C113.593 28.4906 113.201 29.1219 112.752 29.494C112.305 29.8661 111.755 30.0533 111.102 30.0533C110.739 30.0533 110.378 30.0125 110.024 29.9309V28.9707C110.287 29.0283 110.583 29.0571 110.908 29.0571C111.728 29.0571 112.312 28.5962 112.663 27.6721L113.101 26.5463L109.923 18.5938Z"
                    fill={colour}
                />
                <path
                    d="M73.3518 19.3267C73.7295 19.6412 74.5208 20.3037 74.5208 21.5615C74.5208 22.7857 73.8299 23.3642 73.1366 23.9115C72.9214 24.1252 72.6752 24.358 72.6752 24.7229C72.6752 25.0853 72.9214 25.2846 73.1031 25.4334L73.696 25.8967C74.4204 26.5088 75.0803 27.0729 75.0803 28.2131C75.0803 29.7685 73.5813 31.3384 70.7482 31.3384C68.3574 31.3384 67.2051 30.1982 67.2051 28.9716C67.2051 28.3763 67.5015 27.5337 68.4746 26.9528C69.4954 26.3239 70.8797 26.2423 71.6208 26.1919C71.3889 25.8943 71.1259 25.5798 71.1259 25.0685C71.1259 24.7877 71.2096 24.622 71.2909 24.4228C71.1092 24.4396 70.9275 24.4564 70.7625 24.4564C69.0173 24.4564 68.0275 23.1482 68.0275 21.8592C68.0275 21.0982 68.3742 20.2533 69.0818 19.6412C70.0214 18.8635 71.1427 18.7314 72.032 18.7314H75.4246L74.3702 19.3267H73.3518ZM72.1803 26.6744C72.0488 26.6576 71.9651 26.6576 71.8025 26.6576C71.6543 26.6576 70.7626 26.6912 70.0716 26.924C69.7082 27.0561 68.6539 27.4521 68.6539 28.6283C68.6539 29.8021 69.7919 30.6471 71.5539 30.6471C73.1342 30.6471 73.9757 29.8837 73.9757 28.8588C73.9781 28.0114 73.433 27.5673 72.1803 26.6744ZM72.6584 23.5299C73.0362 23.1482 73.0696 22.6201 73.0696 22.3225C73.0696 21.1318 72.362 19.2787 70.9945 19.2787C70.5665 19.2787 70.1051 19.4924 69.8397 19.826C69.56 20.1741 69.4763 20.6206 69.4763 21.0502C69.4763 22.1592 70.1194 23.9955 71.5372 23.9955C71.9508 23.9931 72.3954 23.7939 72.6584 23.5299Z"
                    fill={colour}
                />
                <path
                    d="M62.9785 26.4098C60.363 26.4098 58.9644 24.3622 58.9644 22.5115C58.9644 20.3488 60.724 18.498 63.2247 18.498C65.6418 18.498 67.1551 20.3968 67.1551 22.3963C67.1575 24.3454 65.6609 26.4098 62.9785 26.4098ZM65.0345 25.0728C65.429 24.5447 65.5294 23.8822 65.5294 23.2388C65.5294 21.7842 64.8385 19.0093 62.7992 19.0093C62.2565 19.0093 61.7137 19.2254 61.3193 19.571C60.6785 20.1495 60.5614 20.8745 60.5614 21.5874C60.5614 23.2221 61.3671 25.9129 63.3586 25.9129C63.9993 25.9129 64.6568 25.6009 65.0345 25.0728Z"
                    fill={colour}
                />
                <path
                    d="M54.0629 26.4098C51.4474 26.4098 50.0488 24.3622 50.0488 22.5115C50.0488 20.3488 51.8084 18.498 54.3092 18.498C56.7263 18.498 58.2396 20.3968 58.2396 22.3963C58.2396 24.3454 56.743 26.4098 54.0629 26.4098ZM56.119 25.0728C56.5135 24.5447 56.6139 23.8822 56.6139 23.2388C56.6139 21.7842 55.923 19.0093 53.8836 19.0093C53.3409 19.0093 52.7982 19.2254 52.4037 19.571C51.763 20.1495 51.6459 20.8745 51.6459 21.5874C51.6459 23.2221 52.4516 25.9129 54.4431 25.9129C55.0814 25.9129 55.7389 25.6009 56.119 25.0728Z"
                    fill={colour}
                />
                <path
                    d="M48.7914 26.1482L46.4269 26.6955C45.4682 26.8443 44.6075 26.9763 43.699 26.9763C39.1327 26.9763 37.397 23.6062 37.397 20.9657C37.397 17.7444 39.8619 14.7559 44.0792 14.7559C44.9733 14.7559 45.8316 14.8879 46.6086 15.1039C47.8494 15.452 48.428 15.884 48.7914 16.1337L47.4191 17.4443L46.8405 17.5763L47.2541 16.9114C46.6923 16.3641 45.6667 15.3512 43.7158 15.3512C41.1027 15.3512 39.1351 17.3435 39.1351 20.2504C39.1351 23.3709 41.3848 26.3114 44.9901 26.3114C46.0492 26.3114 46.5943 26.0954 47.0916 25.8961V23.2221L44.5956 23.3541L45.9177 22.6388H49.4249L48.9946 23.0541C48.8775 23.1549 48.8631 23.1885 48.8296 23.3205C48.8129 23.4693 48.7962 23.9518 48.7962 24.1174V26.1482H48.7914Z"
                    fill={colour}
                />
                <path
                    d="M78.5781 25.6725C78.033 25.6221 77.9182 25.5237 77.9182 24.8756V24.6932V15.478C77.9206 15.442 77.923 15.406 77.9278 15.37C77.9947 14.7891 78.1597 14.6883 78.6737 14.3906H76.3069L75.066 14.9883H76.3308V14.9955V14.9883V24.6908V25.2405C76.3308 25.5717 76.2662 25.6221 75.8837 26.119H78.8124L79.4244 25.7541C79.1423 25.7229 78.8602 25.7061 78.5781 25.6725Z"
                    fill={colour}
                />
                <path
                    d="M85.3081 25.8251C85.1288 25.9236 84.9471 26.0412 84.7678 26.1228C84.2251 26.37 83.6657 26.4372 83.1732 26.4372C82.6496 26.4372 81.8272 26.4036 80.988 25.7939C79.8237 24.9682 79.3145 23.5496 79.3145 22.3133C79.3145 19.7569 81.3849 18.5039 83.0751 18.5039C83.6657 18.5039 84.2753 18.6527 84.7678 18.9648C85.5878 19.5073 85.803 20.2178 85.9154 20.5971L82.0567 22.1645L80.792 22.2629C81.2008 24.3561 82.6137 25.5755 84.1749 25.5755C85.0117 25.5755 85.6213 25.2803 86.1784 24.9994L85.3081 25.8251ZM83.7661 20.9091C84.0769 20.7939 84.2418 20.6955 84.2418 20.465C84.2418 19.8049 83.5031 19.0464 82.6161 19.0464C81.9586 19.0464 80.7274 19.5577 80.7274 21.3388C80.7274 21.6196 80.7609 21.9149 80.7776 22.2125L83.7661 20.9091Z"
                    fill={colour}
                />
                <path
                    d="M86.8954 18.6148V19.7022H86.7639V18.6148H86.4053V18.502H87.254V18.6148H86.8954Z"
                    fill={colour}
                />
                <path
                    d="M88.3728 19.7041V18.6047H88.3657L88.031 19.7041H87.9282L87.5911 18.6047H87.5863V19.7041H87.4667V18.5039H87.6723L87.976 19.4737H87.9808L88.2796 18.5039H88.4876V19.7041H88.3728Z"
                    fill={colour}
                />
                <path
                    d="M40.6796 11.5261H39.8571C39.8571 11.4133 39.7782 11.0917 39.6228 10.5612H38.437C38.2768 11.0821 38.1956 11.4037 38.1956 11.5261H37.4233C37.4233 11.4589 37.6242 10.9092 38.0234 9.87466C38.4227 8.84248 38.6235 8.27359 38.6235 8.16797H39.5942C39.5942 8.26639 39.7759 8.82568 40.1369 9.85065C40.5003 10.8732 40.6796 11.4325 40.6796 11.5261ZM39.4937 10.0691C39.2021 9.18094 39.0538 8.70326 39.0538 8.63125H39.0132C39.0132 8.69846 38.8626 9.17854 38.559 10.0691H39.4937Z"
                    fill={colour}
                />
                <path
                    d="M43.9932 11.5261H43.3596C43.3596 11.4565 43.1253 11.0845 42.6543 10.4099C42.1618 9.69943 41.8797 9.21455 41.8128 8.9529H41.7721C41.8152 9.32977 41.8367 9.67062 41.8367 9.97308C41.8367 10.3979 41.8486 10.914 41.8726 11.5261H41.2414C41.2677 10.9932 41.2796 10.4075 41.2796 9.76904C41.2796 9.14253 41.2677 8.60724 41.2414 8.16797H42.0041C42.0041 8.25918 42.2144 8.62164 42.6376 9.25775C43.0871 9.93227 43.3453 10.3907 43.4122 10.6332H43.4505C43.4098 10.2491 43.3883 9.90586 43.3883 9.60101C43.3883 9.13533 43.3764 8.65765 43.3524 8.16797H43.9956C43.9717 8.59524 43.9597 9.12813 43.9597 9.76904C43.9573 10.4147 43.9693 11.0005 43.9932 11.5261Z"
                    fill={colour}
                />
                <path
                    d="M47.7156 9.73136C47.7156 10.2186 47.5602 10.6459 47.247 11.0108C46.9362 11.3756 46.4987 11.5581 45.9369 11.5581C45.6834 11.5581 45.3344 11.5485 44.8897 11.5269C44.9112 11.0084 44.9232 10.4227 44.9232 9.76977C44.9232 9.14326 44.9112 8.61037 44.8897 8.1687H45.3392C45.4133 8.1687 45.5233 8.1663 45.6691 8.1615C45.8149 8.1567 45.9106 8.1543 45.9536 8.1543C46.5704 8.1543 47.0199 8.30792 47.2972 8.61757C47.5745 8.92723 47.7156 9.29929 47.7156 9.73136ZM47.0127 9.84178C47.0127 9.51053 46.9171 9.23448 46.7258 9.00644C46.5346 8.7808 46.2405 8.66798 45.8484 8.66798C45.7743 8.66798 45.6739 8.67518 45.5448 8.69199C45.5615 9.03524 45.5711 9.39531 45.5711 9.76977C45.5711 10.1802 45.5806 10.5883 45.5974 10.9916C45.7097 11.0132 45.8197 11.0252 45.9321 11.0252C46.3003 11.0252 46.5728 10.91 46.7497 10.6819C46.9243 10.4515 47.0127 10.173 47.0127 9.84178Z"
                    fill={colour}
                />
                <path
                    d="M51.1464 11.5264H50.3861C50.1877 10.9167 50.0346 10.5423 49.9295 10.4006C49.8243 10.259 49.6521 10.187 49.4154 10.187C49.315 10.187 49.2218 10.187 49.1309 10.1894C49.1309 10.6311 49.1381 11.0775 49.1548 11.5288H48.44C48.4615 11.0103 48.4735 10.4246 48.4735 9.77172C48.4735 9.14522 48.4615 8.61233 48.44 8.17065H48.8847C48.9349 8.17065 49.0425 8.16825 49.2074 8.16345C49.37 8.15865 49.523 8.15625 49.6617 8.15625C50.4483 8.15625 50.8403 8.42509 50.8403 8.96519C50.8403 9.39246 50.6156 9.69251 50.1685 9.86054V9.89894C50.3407 9.94695 50.4937 10.0814 50.6228 10.3046C50.7495 10.5254 50.9264 10.9335 51.1464 11.5264ZM50.1829 9.15242C50.1829 8.81156 49.9629 8.63873 49.5254 8.63873C49.3652 8.63873 49.2218 8.65073 49.0999 8.67474C49.119 8.94118 49.1285 9.28924 49.1285 9.71651C49.2218 9.71891 49.3031 9.72132 49.3676 9.72132C49.9103 9.72372 50.1829 9.53408 50.1829 9.15242Z"
                    fill={colour}
                />
                <path
                    d="M54.8281 9.78604C54.8281 10.3141 54.668 10.751 54.3452 11.0991C54.0224 11.4471 53.6256 11.6199 53.1498 11.6199C52.698 11.6199 52.3202 11.4615 52.0142 11.1423C51.7106 10.823 51.5576 10.4173 51.5576 9.92766C51.5576 9.39957 51.7177 8.9627 52.0405 8.61464C52.3632 8.26658 52.7601 8.09375 53.2359 8.09375C53.6877 8.09375 54.0655 8.25217 54.3715 8.57143C54.6751 8.88588 54.8281 9.29155 54.8281 9.78604ZM54.1205 9.86045C54.1205 9.50039 54.0296 9.20994 53.8455 8.9867C53.6614 8.76346 53.4367 8.65064 53.1665 8.65064C52.9155 8.65064 52.7051 8.76346 52.5306 8.9891C52.3561 9.21474 52.2676 9.49799 52.2676 9.84364C52.2676 10.2013 52.3585 10.4918 52.5426 10.7174C52.7266 10.9406 52.9514 11.0535 53.2191 11.0535C53.4702 11.0535 53.6806 10.9406 53.8551 10.715C54.032 10.487 54.1205 10.2013 54.1205 9.86045Z"
                    fill={colour}
                />
                <path
                    d="M56.2793 11.5261H55.5526C55.5741 10.9932 55.586 10.4075 55.586 9.76904C55.586 9.14253 55.5741 8.60724 55.5526 8.16797H56.2793C56.2578 8.60004 56.2459 9.13533 56.2459 9.76904C56.2459 10.4171 56.2578 11.0029 56.2793 11.5261Z"
                    fill={colour}
                />
                <path
                    d="M60.0019 9.73136C60.0019 10.2186 59.8465 10.6459 59.5333 11.0108C59.2225 11.3756 58.785 11.5581 58.2231 11.5581C57.9697 11.5581 57.6206 11.5485 57.176 11.5269C57.1975 11.0084 57.2094 10.4227 57.2094 9.76977C57.2094 9.14326 57.1975 8.61037 57.176 8.1687H57.6254C57.6995 8.1687 57.8095 8.1663 57.9554 8.1615C58.1012 8.1567 58.1968 8.1543 58.2399 8.1543C58.8567 8.1543 59.3061 8.30792 59.5835 8.61757C59.8632 8.92723 60.0019 9.29929 60.0019 9.73136ZM59.3014 9.84178C59.3014 9.51053 59.2057 9.23448 59.0145 9.00644C58.8232 8.7808 58.5291 8.66798 58.1371 8.66798C58.0629 8.66798 57.9625 8.67518 57.8334 8.69199C57.8502 9.03524 57.8597 9.39531 57.8597 9.76977C57.8597 10.1802 57.8693 10.5883 57.886 10.9916C57.9984 11.0132 58.1084 11.0252 58.2207 11.0252C58.5889 11.0252 58.8615 10.91 59.0384 10.6819C59.2129 10.4515 59.3014 10.173 59.3014 9.84178Z"
                    fill={colour}
                />
                <path
                    d="M64.9411 11.5261H64.1187C64.1187 11.4133 64.0398 11.0917 63.8844 10.5612H62.6986C62.5384 11.0821 62.4571 11.4037 62.4571 11.5261H61.6849C61.6849 11.4589 61.8857 10.9092 62.285 9.87466C62.6842 8.84248 62.885 8.27359 62.885 8.16797H63.8557C63.8557 8.26639 64.0374 8.82568 64.3984 9.85065C64.7594 10.8732 64.9411 11.4325 64.9411 11.5261ZM63.7553 10.0691C63.4636 9.18094 63.3154 8.70326 63.3154 8.63125H63.2747C63.2747 8.69846 63.1241 9.17854 62.8205 10.0691H63.7553Z"
                    fill={colour}
                />
                <path
                    d="M67.9368 9.04574C67.9368 9.45621 67.7838 9.76106 67.4801 9.9555C67.1765 10.1523 66.8035 10.2483 66.3612 10.2483C66.28 10.2483 66.2226 10.2459 66.1891 10.2435C66.1891 10.5676 66.1987 10.9925 66.2178 11.523H65.4958C65.5173 11.0261 65.5293 10.4404 65.5293 9.76587C65.5293 9.14656 65.5173 8.61367 65.4958 8.1648H65.9429C66.005 8.1648 66.127 8.16239 66.3086 8.15759C66.488 8.15279 66.6434 8.15039 66.7772 8.15039C67.1024 8.15039 67.3773 8.2248 67.6021 8.37123C67.8244 8.52245 67.9368 8.74569 67.9368 9.04574ZM67.2745 9.18737C67.2745 8.82011 67.0307 8.63527 66.5453 8.63527C66.4234 8.63527 66.2943 8.64728 66.1604 8.67368C66.1795 8.99774 66.1891 9.3674 66.1891 9.77787C66.2321 9.78027 66.2823 9.78267 66.3373 9.78267C66.9613 9.78507 67.2745 9.58584 67.2745 9.18737Z"
                    fill={colour}
                />
                <path
                    d="M71.0161 9.04574C71.0161 9.45621 70.8631 9.76106 70.5595 9.9555C70.2558 10.1523 69.8829 10.2483 69.4406 10.2483C69.3593 10.2483 69.3019 10.2459 69.2685 10.2435C69.2685 10.5676 69.278 10.9925 69.2971 11.523H68.5751C68.5967 11.0261 68.6086 10.4404 68.6086 9.76587C68.6086 9.14656 68.5967 8.61367 68.5751 8.1648H69.0222C69.0844 8.1648 69.2063 8.16239 69.388 8.15759C69.5673 8.15279 69.7227 8.15039 69.8566 8.15039C70.1817 8.15039 70.4567 8.2248 70.6814 8.37123C70.9061 8.52245 71.0161 8.74569 71.0161 9.04574ZM70.3539 9.18737C70.3539 8.82011 70.11 8.63527 69.6247 8.63527C69.5027 8.63527 69.3737 8.64728 69.2398 8.67368C69.2589 8.99774 69.2685 9.3674 69.2685 9.77787C69.3115 9.78027 69.3617 9.78267 69.4167 9.78267C70.0431 9.78507 70.3539 9.58584 70.3539 9.18737Z"
                    fill={colour}
                />
                <path
                    d="M76.0535 9.78604C76.0535 10.3141 75.8933 10.751 75.5705 11.0991C75.2478 11.4471 74.8509 11.6199 74.3752 11.6199C73.9233 11.6199 73.5456 11.4615 73.2395 11.1423C72.9359 10.823 72.7829 10.4173 72.7829 9.92766C72.7829 9.39957 72.9431 8.9627 73.2658 8.61464C73.5886 8.26658 73.9855 8.09375 74.4612 8.09375C74.9131 8.09375 75.2908 8.25217 75.5968 8.57143C75.9005 8.88588 76.0535 9.29155 76.0535 9.78604ZM75.3434 9.86045C75.3434 9.50039 75.2526 9.20994 75.0685 8.9867C74.8844 8.76346 74.6597 8.65064 74.3895 8.65064C74.1385 8.65064 73.9281 8.76346 73.7536 8.9891C73.579 9.21474 73.4906 9.49799 73.4906 9.84364C73.4906 10.2013 73.5814 10.4918 73.7679 10.7174C73.952 10.9406 74.1767 11.0535 74.4445 11.0535C74.6955 11.0535 74.9059 10.9406 75.0804 10.715C75.2573 10.487 75.3434 10.2013 75.3434 9.86045Z"
                    fill={colour}
                />
                <path
                    d="M79.5344 11.5261H78.9009C78.9009 11.4565 78.6666 11.0845 78.1956 10.4099C77.7031 9.69943 77.421 9.21455 77.3541 8.9529H77.3134C77.3564 9.32977 77.378 9.67062 77.378 9.97308C77.378 10.3979 77.3899 10.914 77.4138 11.5261H76.7827C76.809 10.9932 76.8209 10.4075 76.8209 9.76904C76.8209 9.14253 76.809 8.60724 76.7827 8.16797H77.5453C77.5453 8.25918 77.7557 8.62164 78.1789 9.25775C78.6283 9.93227 78.8865 10.3907 78.9535 10.6332H78.9917C78.9511 10.2491 78.9296 9.90586 78.9296 9.60101C78.9296 9.13533 78.9176 8.65765 78.8937 8.16797H79.5368C79.5129 8.59524 79.501 9.12813 79.501 9.76904C79.4986 10.4147 79.5105 11.0005 79.5344 11.5261Z"
                    fill={colour}
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_2085_4426"
                        x1="16.2893"
                        y1="7.73331"
                        x2="16.2893"
                        y2="34.9306"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#2079B0" />
                        <stop offset="0.2863" stopColor="#4A93B8" />
                        <stop offset="0.767" stopColor="#83BEBF" />
                        <stop offset="1" stopColor="#97CFBF" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_2085_4426"
                        x1="9.45964"
                        y1="14.484"
                        x2="26.7878"
                        y2="14.484"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#5ABCAB" />
                        <stop offset="1" stopColor="#E6E99A" />
                    </linearGradient>
                    <linearGradient
                        id="paint2_linear_2085_4426"
                        x1="18.1236"
                        y1="21.3311"
                        x2="18.1236"
                        y2="35.0284"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E53432" />
                        <stop offset="0.1668" stopColor="#D73948" />
                        <stop offset="0.5748" stopColor="#B33F72" />
                        <stop offset="0.8619" stopColor="#9A4189" />
                        <stop offset="1" stopColor="#904192" />
                    </linearGradient>
                    <linearGradient
                        id="paint3_linear_2085_4426"
                        x1="28.1037"
                        y1="17.6785"
                        x2="28.1037"
                        y2="24.9833"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F18779" />
                        <stop offset="0.1194" stopColor="#F2917A" />
                        <stop offset="0.7128" stopColor="#FABC77" />
                        <stop offset="1" stopColor="#FDCB72" />
                    </linearGradient>
                </defs>
            </svg>
        </motion.div>
    )
}

export default playStoreIcon
