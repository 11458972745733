import * as React from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { AnimatePresence, motion } from 'framer-motion'
import { fadeIn } from '@/lib/utils/framer-motion-config'

import { StandardPageQuery_entry_page_page_Entry } from '@/gql/types/StandardPageQuery'
import { SiteWideQuery_globalSet } from '@/gql/types/SiteWideQuery'
import { StandardArticlesQuery_entry_articlesArchive_articlesArchive_Entry } from '@/gql/types/StandardArticlesQuery'

import okoLogo from '@/svg/oko-logo'
import Curve from '@/svg/curves'
import loginUser from '@/svg/login-user'

type AppHeaderType = {
    siteWide: SiteWideQuery_globalSet
    entry?:
        | StandardPageQuery_entry_page_page_Entry
        | StandardArticlesQuery_entry_articlesArchive_articlesArchive_Entry
    bgColor?: string
    mode?: 'fixed' | 'sticky'
    textColour?: string
    bgVideo?: boolean
}

const AppHeader: React.FunctionComponent<AppHeaderType> = ({
    siteWide,
    entry,
    bgColor = 'bg-transparent',
    mode = 'sticky',
    bgVideo,
}) => {
    const [dynamicClass, setDynamicClass] = React.useState(bgColor)
    const [mainMenuOpen, setMainMenuOpen] = React.useState(false)
    const [showHeader, setShowHeader] = React.useState(true)
    const [animateOnce, setAnimateOnce] = React.useState<boolean>()
    const [textColour, setTextColour] = React.useState<string>()
    const [bannerColour, setBannerColour] = React.useState<string>(
        entry && entry.modules && entry.modules.length > 0
            ? entry
                  .modules!.map((module: any) => module)
                  .filter((item: any) => item?.typeHandle! === 'secondaryBanner')
                  .map((val: any) => val?.theme!)[0]
            : ''
    )
    const siteheaderRef = React.useRef<HTMLElement>(null)
    const router = useRouter()

    React.useEffect(() => {
        let session = window.sessionStorage.getItem('visited-site')

        const app = document?.getElementById('#app')
        if (mainMenuOpen) app?.classList.add('h-screen')
        else app?.classList.remove('h-screen')

        if (bannerColour === 'purple') {
            setTextColour('text-white')
        } else {
            if (bgVideo) setTextColour('text-white')
            else setTextColour('text-black')
        }

        if (session === 'true') {
            setAnimateOnce(true)
        } else {
            setAnimateOnce(false)
        }
    }, [animateOnce, bannerColour, bgVideo, mainMenuOpen])

    let animationFrameWorker: any
    let previousScroll: number = 0

    React.useEffect(() => {
        window.addEventListener('resize', (e) => {
            if (window.innerWidth > 1280) {
                setMainMenuOpen(false)
            }
        })
        // Only show the header when scrolling up
        document.addEventListener('scroll', (e) => {
            const cleansed = window.scrollY < 0 ? 0 : window.scrollY // Some browsers let you scroll above 0px which leads to bugs here unless we clean it up
            if (!mainMenuOpen) {
                setShowHeader(cleansed < previousScroll || cleansed == 0)
                if (cleansed > previousScroll || cleansed == 0) {
                    setTextColour(
                        bannerColour === 'purple' || bgVideo ? 'text-white' : 'text-black'
                    )
                    setDynamicClass('bg-transparent')
                } else {
                    setTextColour('text-black')
                    setDynamicClass('bg-white shadow-[0px_2px_0px_0px_#F2F2F2]')
                }
            } else {
                setShowHeader(true)
                setDynamicClass('bg-transparent')
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
            previousScroll = cleansed
        })
    }, [mainMenuOpen, bgColor])

    React.useEffect(() => {
        if (siteheaderRef.current !== null) {
            document.documentElement.style.setProperty(
                '--site-header-height',
                `${siteheaderRef.current.clientHeight}px`
            )
        }
    }, [siteheaderRef])

    const container = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
                duration: 0.5,
                staggerChildren: 0.15,
            },
        },
    }

    const item = {
        hidden: { opacity: 0, translateY: -40 },
        show: { opacity: 1, translateY: 0 },
    }

    // Close everything when the routes change
    React.useEffect(() => {
        router.events.on('routeChangeComplete', () => {
            setMainMenuOpen(false)
        })
    }, [router.events])

    return (
        <>
            <header
                ref={siteheaderRef}
                id="site-header"
                className={`
                ${mode === 'sticky' ? `sticky` : `fixed`}
                left-0 top-0 z-40 flex  w-full transform items-center justify-between px-5 py-3 font-sans transition duration-300 md:px-10 md:py-[17px]
                ${dynamicClass}
                ${showHeader ? 'translate-y-0' : '-translate-y-full'}
                ${textColour}
        `}
            >
                {showHeader}
                <Link
                    href="/"
                    title="Go home"
                    aria-label="Go home"
                    className={`max-w-[72px] fill-current text-base font-bold transition delay-75 duration-500 hover:scale-110 md:text-xl ${
                        mainMenuOpen ? 'text-white' : textColour
                    }`}
                >
                    {okoLogo()}
                </Link>
                <div className={`flex items-center ${textColour}`}>
                    {/* // TODO: Refactor code and remove duplicate (one motion & one normal) */}
                    {!animateOnce ? (
                        <motion.ul
                            {...fadeIn}
                            className="hidden flex-row items-center space-x-8 whitespace-nowrap text-md font-black xl:flex"
                        >
                            {siteWide.primaryNavigation?.map((link, index) => (
                                <li key={index}>
                                    <Link
                                        href={link?.linkUrl!}
                                        className={`drawn-underline drawn-underline-bold whitespace-nowrap ${
                                            router.asPath === link?.linkUrl!
                                                ? 'cursor-default border-b-2'
                                                : ''
                                        }`}
                                    >
                                        {link?.linkText}
                                    </Link>
                                </li>
                            ))}
                            <div className="hidden h-[60px] border border-black border-opacity-10 xl:block"></div>
                            <li>
                                <div className="flex items-center space-x-[20px]">
                                    {siteWide.loginUrl && (
                                        <Link
                                            href={siteWide.loginUrl!}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="px-4 py-2 text-base font-black"
                                        >
                                            <div className="group flex items-center space-x-2">
                                                {loginUser(`fill-transparent`)}
                                                <span>Login</span>
                                            </div>
                                        </Link>
                                    )}
                                    {siteWide.signUpUrl && (
                                        <Link
                                            href={siteWide.signUpUrl!}
                                            className={`rounded-full border-2 px-5 py-2 text-base font-black transition duration-500 ease-in ${
                                                textColour === 'text-white'
                                                    ? 'text-white hover:border-white hover:bg-white hover:text-black'
                                                    : 'text-black hover:border-black hover:bg-black hover:text-white'
                                            } transition duration-500 ease-in-out`}
                                        >
                                            Sign up
                                        </Link>
                                    )}
                                </div>
                            </li>
                        </motion.ul>
                    ) : (
                        <ul className="hidden flex-row items-center space-x-8 whitespace-nowrap text-md font-black xl:flex">
                            {siteWide.primaryNavigation?.map((link, index) => (
                                <li key={index}>
                                    <Link
                                        href={link?.linkUrl!}
                                        className={`drawn-underline drawn-underline-bold whitespace-nowrap ${
                                            router.asPath === link?.linkUrl!
                                                ? 'cursor-default border-b-2'
                                                : ''
                                        }`}
                                    >
                                        {link?.linkText}
                                    </Link>
                                </li>
                            ))}
                            <div className="hidden h-[60px] border border-black border-opacity-10 xl:block"></div>
                            <li>
                                <div className="flex items-center space-x-[20px]">
                                    {siteWide.loginUrl && (
                                        <a
                                            href={siteWide.loginUrl!}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="px-4 py-2 text-base font-black transition-opacity duration-500 hover:opacity-50"
                                        >
                                            <div className="group flex items-center space-x-2">
                                                {loginUser('fill-transparent')}
                                                <span>Login</span>
                                            </div>
                                        </a>
                                    )}
                                    {siteWide.signUpUrl && (
                                        <Link
                                            href={siteWide.signUpUrl!}
                                            className={`rounded-full border-2 px-5 py-2 text-base font-black transition duration-500 ease-in ${
                                                textColour === 'text-white'
                                                    ? 'text-white hover:border-white hover:bg-white hover:text-black'
                                                    : 'text-black hover:border-black hover:bg-black hover:text-white'
                                            } transition duration-500 ease-in-out`}
                                        >
                                            Sign up
                                        </Link>
                                    )}
                                </div>
                            </li>
                        </ul>
                    )}
                    <button
                        onClick={(e) => {
                            setDynamicClass('bg-transparent')
                            setMainMenuOpen((current) => {
                                return !current
                            })
                        }}
                        aria-label="Toggle menu"
                        className={`hamburger hamburger--spin xl:hidden ${
                            mainMenuOpen ? 'is-active !text-white' : ''
                        }`}
                        type="button"
                    >
                        <span className="hamburger-box">
                            <span className="hamburger-inner"></span>
                        </span>
                    </button>
                </div>
            </header>
            <AnimatePresence mode="wait">
                {mainMenuOpen && (
                    <motion.nav
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        key="main-menu"
                        className="fixed left-0 top-0 z-30 flex h-full w-full flex-col bg-purple text-white xl:hidden"
                    >
                        {/* Main nav */}
                        <div className="relative h-full px-5 pt-[90px] md:px-10 md:pt-[110px] lg:pt-[120px]">
                            <motion.ul
                                variants={container}
                                initial="hidden"
                                animate="show"
                                className="text-lg font-black md:text-3xl"
                            >
                                <div className="flex flex-col space-y-8 overflow-scroll text-left md:space-y-10">
                                    {siteWide.primaryNavigation?.map((link, index) => (
                                        <motion.li variants={item} key={index}>
                                            <Link
                                                href={link?.linkUrl!}
                                                className="drawn-underline drawn-underline-bold"
                                            >
                                                {link?.linkText}
                                            </Link>
                                        </motion.li>
                                    ))}
                                    <hr className="max-w-[512px] border border-white border-opacity-20" />
                                    {siteWide.loginUrl && (
                                        <motion.li variants={item}>
                                            <a
                                                href={siteWide.loginUrl!}
                                                className="font-current py-2 pr-4 text-base"
                                                target="_blank"
                                            >
                                                <div className="flex items-center space-x-3">
                                                    {loginUser(
                                                        'fill-transparent stroke-white',
                                                        '17'
                                                    )}
                                                    <span>Login</span>
                                                </div>
                                            </a>
                                        </motion.li>
                                    )}
                                </div>
                            </motion.ul>
                            {siteWide.signUpUrl && (
                                <div className="absolute inset-x-0 bottom-0">
                                    {Curve('rotate-180 -mb-px fill-black')}
                                    <div className="flex items-center justify-center bg-black py-8 md:pb-11 md:pt-16">
                                        <Link
                                            href={siteWide.signUpUrl!}
                                            className="w-full max-w-[250px] rounded-full border-2 py-2 text-center text-base font-black text-white"
                                        >
                                            Sign up
                                        </Link>
                                    </div>
                                </div>
                            )}
                        </div>
                    </motion.nav>
                )}
            </AnimatePresence>
        </>
    )
}

export default AppHeader
