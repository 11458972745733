import * as React from 'react'
import Link from 'next/link'

import { buttonMapping } from '@/config/ts/tailwind'
import { motion } from 'framer-motion'
import { fadeIn } from '@/lib/utils/framer-motion-config'

type ButtonType = {
    children: React.ReactNode
    classes?: string
    url: string
    /* Define as the background 'theme' colour of the parent container */
    theme?: string | null
    button?: boolean
    target?: string
    title?: string
    ariaLabel?: string
    transition?: boolean | null
}

const ButtonSecondary: React.FunctionComponent<ButtonType> = ({
    children,
    url,
    classes,
    theme = 'white',
    button = false,
    target,
    title,
    ariaLabel,
    transition = true,
}) => {
    const [dynamicClass, setDynamicClass] = React.useState(
        theme ? buttonMapping[theme] : buttonMapping['white']
    )

    React.useEffect(() => {
        setDynamicClass(buttonMapping[theme!])
    }, [theme])

    return (
        <motion.div {...fadeIn}>
            <Link
                href={url! !== null ? url! : '/'}
                title={title}
                aria-label={ariaLabel}
                className={
                    button
                        ? `font-sans font-black ${classes} flex h-[50px] w-full max-w-[266px] cursor-pointer content-center justify-center rounded-full border-2 py-2 leading-[30px] sm:max-w-[300px] ${dynamicClass}`
                        : classes
                }
                target={target}
            >
                {children}
            </Link>
        </motion.div>
    )
}

export default ButtonSecondary
