// Helpers to transition items
// ADD to motion html element if you want to play transition/animation once ---> viewport={{ once: true }}

// Standard fade in
const fadeInVariants = (delay: number = 0.2, duration: number = 0.6) => {
    return {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                duration: duration,
                delay: delay,
            },
        },
    }
}

// Add variable to parent of child items
const motionContainer = (delay: number = 0.3, direction: number = 1) => {
    return {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                // Delays transition of each child by 0.3 seconds
                staggerChildren: delay,
                staggerDirection: direction,
            },
        },
    }
}

// Add variable to child item in `container`
const motionItem = (axis: 'y' | 'x', duration: number = 0.3, distance: number = 150) => {
    if (axis === 'y') {
        return {
            hidden: { y: distance, opacity: 0 },
            visible: {
                opacity: 1,
                transition: { ease: 'easeOut', duration: duration },
                y: 0,
            },
        }
    } else if (axis === 'x') {
        return {
            hidden: { x: distance, opacity: 0 },
            visible: {
                opacity: 1,
                transition: { ease: 'easeOut', duration: duration },
                x: 0,
            },
        }
    }
    return
}

const fadeIn = {
    variants: fadeInVariants(),
    initial: 'hidden',
    whileInView: 'visible',
    viewport: { once: true },
}

export { fadeInVariants, motionContainer, motionItem, fadeIn }
