import { motion } from 'framer-motion'
import { fadeIn } from '@/lib/utils/framer-motion-config'

function appStoreIcon(classes?: string, colour = 'white', background = 'black') {
    return (
        <motion.div {...fadeIn}>
            <svg
                width="123"
                height="41"
                viewBox="0 0 123 41"
                fill={background}
                xmlns="http://www.w3.org/2000/svg"
                className={classes}
            >
                <path
                    d="M5.40522 39.6621H5.40482C2.6976 39.6642 0.5 37.4672 0.5 34.7569V5.56733C0.5 2.85958 2.69747 0.662109 5.40522 0.662109H116.883C119.59 0.662109 121.788 2.85958 121.788 5.56733V34.7569C121.788 37.467 119.593 39.6621 116.883 39.6621H5.40522Z"
                    stroke={colour}
                />
                <path
                    d="M31.89 26.6012C31.243 28.0357 30.9336 28.6756 30.0992 29.9437C28.9366 31.7134 27.2958 33.9191 25.2659 33.9378C23.461 33.9542 22.9969 32.7635 20.5475 32.7775C18.098 32.7916 17.587 33.9589 15.7822 33.9425C13.7523 33.9237 12.1982 31.9337 11.0356 30.1617C7.78216 25.2112 7.44462 19.4004 9.44873 16.3134C10.8739 14.1195 13.1217 12.835 15.236 12.835C17.3878 12.835 18.7426 14.0163 20.5217 14.0163C22.2492 14.0163 23.3016 12.835 25.7886 12.835C27.6708 12.835 29.6632 13.8593 31.0836 15.6313C26.4309 18.1792 27.188 24.8244 31.89 26.6012Z"
                    fill={colour}
                />
                <path
                    d="M23.9017 10.8555C24.8064 9.69525 25.4932 8.05446 25.2424 6.38086C23.7657 6.48165 22.0382 7.42159 21.0279 8.64749C20.1115 9.76088 19.3567 11.4087 19.6497 13.012C21.2647 13.0635 22.9336 12.1002 23.9017 10.8555Z"
                    fill={colour}
                />
                <path
                    d="M41.798 16.2412L37.1382 29.9535H39.2712L40.6612 25.7437H45.171L46.6008 29.9535H48.7924L44.1139 16.2412H41.798ZM42.902 18.4961C43.0591 19.1079 43.2302 19.7431 43.4482 20.4299L44.7045 24.0654H41.1323L42.3699 20.4065C42.5575 19.8134 42.7356 19.1735 42.902 18.4961Z"
                    fill={colour}
                />
                <path
                    d="M54.8236 19.7949C53.4101 19.7949 52.278 20.3411 51.5256 21.3771L51.4435 20.0129H49.5449L49.5566 20.1864C49.6129 21.0349 49.6363 21.9725 49.6363 23.3132V33.8822H51.7108V28.9505C52.3554 29.7217 53.382 30.1694 54.547 30.1694C55.7588 30.1694 56.8769 29.6935 57.6902 28.8333C58.5997 27.8723 59.0779 26.4917 59.0779 24.8415C59.0779 23.3836 58.6607 22.1225 57.8731 21.1966C57.1019 20.2942 56.019 19.7949 54.8236 19.7949ZM54.3079 21.5131C56.1573 21.5131 56.9847 23.2265 56.9847 24.9235C56.9847 27.1128 55.9276 28.4747 54.2282 28.4747C53.0679 28.4747 52.0647 27.6848 51.7834 26.5503C51.7342 26.3745 51.7108 26.1424 51.7108 25.8775V24.2086C51.7108 24.0586 51.7436 23.8734 51.7764 23.6976C51.7858 23.6414 51.7975 23.5851 51.8069 23.5289C52.1163 22.3405 53.1429 21.5131 54.3079 21.5131Z"
                    fill={colour}
                />
                <path
                    d="M65.5073 19.7949C64.0938 19.7949 62.9617 20.3411 62.2093 21.3771L62.1272 20.0129H60.231L60.2403 20.1864C60.2966 21.0372 60.32 21.9725 60.32 23.3132V33.8822H62.3945V28.9505C63.039 29.7217 64.0657 30.1694 65.2307 30.1694C66.4425 30.1694 67.5606 29.6935 68.3763 28.8333C69.2834 27.8723 69.7639 26.4917 69.7639 24.8415C69.7639 23.3836 69.3467 22.1225 68.5568 21.1966C67.7856 20.2942 66.7027 19.7949 65.5073 19.7949ZM64.9916 21.5131C66.841 21.5131 67.6684 23.2265 67.6684 24.9235C67.6684 27.1128 66.6113 28.4747 64.9119 28.4747C63.7516 28.4747 62.746 27.6848 62.4671 26.5503C62.4179 26.3745 62.3945 26.1424 62.3945 25.8775V24.2086C62.3945 24.0563 62.4273 23.8734 62.4601 23.6953C62.4695 23.639 62.4812 23.5828 62.4906 23.5289C62.8 22.3405 63.8266 21.5131 64.9916 21.5131Z"
                    fill={colour}
                />
                <path
                    d="M78.8938 22.0502C76.9577 21.3001 76.3366 20.7118 76.3366 19.6218C76.3366 18.7053 77.0655 17.7795 78.6946 17.7795C80.0377 17.7795 80.8979 18.2459 81.13 18.3912L81.3058 18.4991L81.8918 16.7669L81.7769 16.6989C81.43 16.495 80.4385 16.0215 78.7555 16.0215C76.1092 16.0215 74.2621 17.5826 74.2621 19.8187C74.2621 21.6212 75.4201 22.8823 77.9047 23.7847C79.665 24.4434 80.3213 25.1161 80.3213 26.2717C80.3213 27.5796 79.3111 28.3907 77.6843 28.3907C76.6249 28.3907 75.5349 28.0813 74.6934 27.5421L74.5129 27.4273L73.9551 29.1993L74.0582 29.2673C74.8411 29.79 76.3155 30.1697 77.5648 30.1697C81.13 30.1697 82.3957 27.9945 82.3957 26.1334C82.3934 24.216 81.3785 23.0323 78.8938 22.0502Z"
                    fill={colour}
                />
                <path
                    d="M86.1274 17.6494L84.0928 18.2893V20.0121H82.6021V21.6693H84.0928V26.7487C84.0928 27.977 84.3272 28.8372 84.8101 29.381C85.2742 29.8897 85.9868 30.1686 86.8165 30.1686C87.6018 30.1686 88.1573 30.0139 88.4198 29.9201L88.537 29.878L88.4386 28.2161L88.2393 28.277C87.9768 28.3567 87.7072 28.3895 87.2736 28.3895C86.4696 28.3895 86.1274 27.8809 86.1274 26.6878V21.6717H88.6308V20.0145H86.1274V17.6494V17.6494Z"
                    fill={colour}
                />
                <path
                    d="M93.7712 19.7949C90.8693 19.7949 88.8418 21.9608 88.8418 25.0618C88.8418 28.0692 90.8037 30.1717 93.6141 30.1717H93.6329C96.0073 30.1717 98.5623 28.5169 98.5623 24.8837C98.5623 23.407 98.1052 22.1389 97.2403 21.213C96.3707 20.2872 95.1705 19.7949 93.7712 19.7949ZM93.7126 21.4334C95.5784 21.4334 96.4293 23.2523 96.4293 24.9446C96.4293 27.0238 95.269 28.5333 93.6727 28.5333H93.6516C92.0788 28.5333 90.935 27.0495 90.935 25.0032C90.9373 23.2265 91.7952 21.4334 93.7126 21.4334Z"
                    fill={colour}
                />
                <path
                    d="M104.917 19.891L104.792 19.8606C104.61 19.816 104.46 19.7949 104.277 19.7949C103.192 19.7949 102.216 20.4348 101.675 21.4662L101.614 20.0153H99.7529L99.7646 20.1887C99.8209 21.0091 99.842 21.9326 99.842 23.1773L99.8232 29.9561H101.898V24.6681C101.898 24.3797 101.916 24.1078 101.954 23.8641C102.188 22.5936 103.044 21.7732 104.136 21.7732C104.349 21.7732 104.516 21.7896 104.694 21.8084L104.917 21.8318V19.891V19.891Z"
                    fill={colour}
                />
                <path
                    d="M109.581 19.7773C106.82 19.7773 104.891 21.983 104.891 25.1451C104.891 28.1875 106.81 30.1541 109.78 30.1541C111.341 30.1541 112.429 29.8119 113.064 29.5236L113.188 29.4674L112.79 27.9203L112.616 27.9906C112.051 28.2204 111.299 28.4548 110.017 28.4548C109.114 28.4548 107.038 28.1571 106.909 25.4474L113.606 25.4685L113.634 25.3349C113.676 25.124 113.697 24.8403 113.697 24.47C113.697 22.3042 112.619 19.7773 109.581 19.7773ZM109.443 21.3548C110.076 21.3548 110.586 21.5494 110.966 21.9361C111.564 22.5432 111.667 23.4386 111.681 23.8676H106.949C107.143 22.6698 107.891 21.3548 109.443 21.3548Z"
                    fill={colour}
                />
                <path
                    d="M37.1381 6.87189C37.7171 6.79454 38.3898 6.73828 39.1281 6.73828C40.4665 6.73828 41.4182 7.00784 42.0487 7.53758C42.7003 8.06732 43.0754 8.82677 43.0754 9.87687C43.0754 10.9363 42.7003 11.8013 42.037 12.399C41.3526 13.0061 40.2392 13.3342 38.8375 13.3342C38.1624 13.3342 37.6186 13.3155 37.1357 13.2569V6.87189H37.1381ZM38.0804 12.6217C38.3148 12.6592 38.657 12.6592 39.0226 12.6592C41.0127 12.6685 42.0933 11.6583 42.0933 9.905C42.105 8.35563 41.1416 7.40163 39.1727 7.40163C38.6921 7.40163 38.3265 7.44148 38.0804 7.48835V12.6217Z"
                    fill={colour}
                />
                <path
                    d="M46.4134 13.3819C45.0211 13.3819 43.9312 12.4584 43.9312 10.984C43.9312 9.42294 45.075 8.50879 46.4978 8.50879C47.9862 8.50879 48.9918 9.48154 48.9918 10.8973C48.9918 12.6295 47.6534 13.3819 46.4251 13.3819H46.4134ZM46.4462 12.7467C47.344 12.7467 48.0191 11.9849 48.0191 10.9278C48.0191 10.1379 47.5807 9.14635 46.4673 9.14635C45.3657 9.14635 44.8828 10.0699 44.8828 10.9559C44.8828 11.9755 45.5251 12.7467 46.4345 12.7467H46.4462Z"
                    fill={colour}
                />
                <path
                    d="M50.4825 8.61719L51.1787 10.9776C51.317 11.4979 51.4576 11.9878 51.5537 12.4707H51.5865C51.7037 11.9995 51.8748 11.4979 52.0459 10.987L52.8921 8.61719H53.6844L54.486 10.9471C54.6782 11.4956 54.8283 11.9972 54.9455 12.4683H54.9783C55.065 11.9972 55.2033 11.505 55.3744 10.9565L56.1128 8.61719H57.0433L55.3744 13.277H54.5188L53.7266 11.0526C53.5438 10.5228 53.3937 10.0611 53.2672 9.51259H53.2461C53.1171 10.0705 52.9578 10.5627 52.7749 11.062L51.9405 13.277H51.0849L49.5215 8.61719H50.4825V8.61719Z"
                    fill={colour}
                />
                <path
                    d="M57.9831 9.87728C57.9831 9.38505 57.9714 9.00063 57.9409 8.61622H58.7754L58.8293 9.37801H58.8504C59.1082 8.94438 59.7059 8.51074 60.5615 8.51074C61.2787 8.51074 62.3921 8.89516 62.3921 10.4937V13.2761H61.4522V10.5899C61.4522 9.83978 61.1404 9.20456 60.2544 9.20456C59.645 9.20456 59.1621 9.59835 58.991 10.0718C58.9488 10.1773 58.9277 10.332 58.9277 10.4656V13.2784H57.9855V9.87728H57.9831Z"
                    fill={colour}
                />
                <path d="M63.9204 6.43945H64.8627V13.2768H63.9204V6.43945Z" fill={colour} />
                <path
                    d="M68.5335 13.3819C67.1436 13.3819 66.0513 12.4584 66.0513 10.984C66.0513 9.42294 67.1951 8.50879 68.6203 8.50879C70.1064 8.50879 71.1119 9.48154 71.1119 10.8973C71.1119 12.6295 69.7735 13.3819 68.5429 13.3819H68.5335ZM68.5664 12.7467C69.4641 12.7467 70.1392 11.9849 70.1392 10.9278C70.1392 10.1379 69.7008 9.14635 68.5874 9.14635C67.4858 9.14635 67.0029 10.0699 67.0029 10.9559C67.0029 11.9755 67.6452 12.7467 68.5546 12.7467H68.5664Z"
                    fill={colour}
                />
                <path
                    d="M75.9495 12.158C75.9495 12.5611 75.9706 12.9573 76.0245 13.2737H75.1784L75.0916 12.6877H75.0612C74.7822 13.0534 74.215 13.3815 73.4766 13.3815C72.4289 13.3815 71.8921 12.7182 71.8921 12.0431C71.8921 10.9157 73.0055 10.2992 75.0049 10.3109V10.2148C75.0049 9.8304 74.8877 9.12721 73.8282 9.13659C73.336 9.13659 72.832 9.26082 72.4687 9.48349L72.2554 8.91625C72.6844 8.67482 73.3149 8.51074 73.9665 8.51074C75.5604 8.51074 75.9448 9.48349 75.9448 10.4164V12.158H75.9495ZM75.0283 10.8969C74.0017 10.8782 72.8344 11.0422 72.8344 11.947C72.8344 12.5049 73.2399 12.7557 73.711 12.7557C74.3955 12.7557 74.8338 12.3713 74.9838 11.9751C75.0166 11.8884 75.026 11.7923 75.026 11.7056V10.8969H75.0283Z"
                    fill={colour}
                />
                <path
                    d="M81.9291 6.43945V12.072C81.9291 12.4869 81.9409 12.9581 81.9713 13.2768H81.1252L81.083 12.4682H81.0619C80.7735 12.9885 80.1524 13.3823 79.3062 13.3823C78.0545 13.3823 77.0794 12.4283 77.0794 11.0126C77.0677 9.45147 78.1506 8.50919 79.4023 8.50919C80.2063 8.50919 80.7407 8.84672 80.9658 9.21238H80.9869V6.43945H81.9291ZM80.9892 10.5133C80.9892 10.3984 80.9798 10.2625 80.947 10.1476C80.8087 9.61789 80.2954 9.17488 79.5875 9.17488C78.6147 9.17488 78.0358 9.94605 78.0358 10.9657C78.0358 11.9197 78.5608 12.6979 79.5664 12.6979C80.1969 12.6979 80.7759 12.3135 80.947 11.6876C80.9798 11.5728 80.9892 11.4556 80.9892 11.322V10.5133V10.5133Z"
                    fill={colour}
                />
                <path
                    d="M87.869 13.3819C86.479 13.3819 85.3867 12.4584 85.3867 10.984C85.3867 9.42294 86.5329 8.50879 87.9557 8.50879C89.4441 8.50879 90.4497 9.48154 90.4497 10.8973C90.4497 12.6295 89.1113 13.3819 87.8831 13.3819H87.869ZM87.9018 12.7467C88.7996 12.7467 89.4746 11.9849 89.4746 10.9278C89.4746 10.1379 89.0363 9.14635 87.9229 9.14635C86.8212 9.14635 86.3384 10.0699 86.3384 10.9559C86.3384 11.9755 86.9806 12.7467 87.8901 12.7467H87.9018Z"
                    fill={colour}
                />
                <path
                    d="M91.6355 9.87728C91.6355 9.38505 91.6237 9.00063 91.5933 8.61622H92.4277L92.4816 9.37801H92.5027C92.7606 8.94438 93.3583 8.51074 94.2138 8.51074C94.9311 8.51074 96.0445 8.89516 96.0445 10.4937V13.2761H95.1022V10.5899C95.1022 9.83978 94.7928 9.20456 93.9044 9.20456C93.295 9.20456 92.8121 9.59835 92.641 10.0718C92.5988 10.1773 92.5777 10.332 92.5777 10.4656V13.2784H91.6355V9.87728V9.87728Z"
                    fill={colour}
                />
                <path
                    d="M100.976 7.49902V8.61476H102.324V9.25935H100.976V11.7721C100.976 12.3487 101.159 12.6769 101.682 12.6769C101.937 12.6769 102.087 12.6581 102.228 12.6183L102.27 13.2629C102.087 13.3215 101.799 13.3777 101.436 13.3777C100.997 13.3777 100.643 13.2418 100.418 13.0214C100.161 12.7613 100.055 12.3464 100.055 11.7979V9.2617H99.2534V8.6171H100.055V7.75921L100.976 7.49902Z"
                    fill={colour}
                />
                <path
                    d="M103.384 6.43945H104.326V9.34833H104.347C104.497 9.1069 104.731 8.88657 105.022 8.75062C105.301 8.59591 105.632 8.50919 105.985 8.50919C106.679 8.50919 107.793 8.8936 107.793 10.5016V13.2745H106.85V10.6C106.85 9.83822 106.541 9.21238 105.653 9.21238C105.043 9.21238 104.572 9.59679 104.389 10.0609C104.335 10.1758 104.324 10.3023 104.324 10.4664V13.2792H103.381V6.43945H103.384Z"
                    fill={colour}
                />
                <path
                    d="M109.858 11.0891C109.879 12.2353 110.683 12.7065 111.623 12.7065C112.298 12.7065 112.715 12.601 113.057 12.4744L113.228 13.0721C112.895 13.2081 112.319 13.3698 111.494 13.3698C109.909 13.3698 108.946 12.4158 108.946 11.0211C108.946 9.60538 109.876 8.49902 111.386 8.49902C113.076 8.49902 113.514 9.83744 113.514 10.6953C113.514 10.8688 113.505 11.0024 113.484 11.0985L109.858 11.0891ZM112.609 10.4844C112.619 9.95463 112.363 9.10611 111.313 9.10611C110.35 9.10611 109.944 9.89604 109.869 10.4844H112.609Z"
                    fill={colour}
                />
            </svg>
        </motion.div>
    )
}

export default appStoreIcon
