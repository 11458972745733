import * as React from 'react'
import Link from 'next/link'

import { SiteWideQuery_globalSet } from '@/gql/types/SiteWideQuery'

import { motion } from 'framer-motion'
import { fadeIn } from '@/lib/utils/framer-motion-config'

import ButtonPrimary from '@/components/ButtonPrimary'
import socialLinks from '@/lib/content/social-links'
import ButtonSecondary from '@/components/ButtonSecondary'

// SVG's
import appStoreIcon from '@/svg/app-store'
import playStoreIcon from '@/svg/play-store'
import techtalentCharity from '@/svg/logo-techtalent-charity'
import Curve from '@/svg/curves'

type AppFooterType = {
    siteWide: SiteWideQuery_globalSet
    bgColor?: string
}

const AppFooter: React.FunctionComponent<AppFooterType> = ({
    siteWide,
    bgColor = 'bg-transparent text-black',
}) => {
    const [dynamicClass, setDynamicClass] = React.useState(bgColor)

    const date = new Date()

    const signature = siteWide && siteWide.footerSignature!.replace(
        '{{ year }}',
        date.getFullYear().toString()
    )

    React.useEffect(() => {
        setDynamicClass(bgColor)
    }, [bgColor])

    return (
        <footer className="-mt-3 lg:-mt-4 2xl:-mt-10">
            {Curve('rotate-180 -mb-1 fill-black')}
            <div
                className={`left-0 top-0 z-30 w-full transform px-5 pb-[50px] pt-[120px] text-white transition duration-300 md:px-[34px] md:pb-[64px] md:pt-[161px] lg:px-20 lg:pt-[161px] ${dynamicClass}`}
            >
                <div className="mb-16 w-full md:mb-20">
                    <div className="lg:flex">
                        <div className="lg:w-1/2">
                            <motion.h2
                                className="mb-11 max-w-[590px] text-5xl font-black leading-[50px] md:mb-[50px] md:text-7xl md:leading-[90px] lg:text-6xl xl:leading-[70px] 2xl:text-7xl 2xl:leading-[90px]"
                                {...fadeIn}
                            >
                                Are you ready to OKO
                            </motion.h2>
                            {siteWide.signUpUrl && (
                                <motion.div {...fadeIn}>
                                    <ButtonPrimary
                                        data={{
                                            __typename: 'modules_generalButton_BlockType',
                                            typeHandle: 'generalButton',
                                            buttonUrl: siteWide.signUpUrl,
                                            buttonTitle: 'Sign up now',
                                            linkOrSection: null,
                                            block: null,
                                        }}
                                        theme="footer"
                                    />
                                </motion.div>
                            )}
                        </div>
                        <div className="mt-20 md:flex lg:mt-0 lg:w-1/2 lg:text-right">
                            <div className="h-auto text-md font-black md:w-1/2 md:text-lg">
                                <ul className="list-reset flex flex-col space-y-[27px] leading-normal">
                                    {siteWide.primaryNavigation?.map((link, index) => (
                                        <motion.li key={index} {...fadeIn}>
                                            <Link
                                                href={link?.linkUrl!}
                                                className="drawn-underline drawn-underline-bold"
                                            >
                                                {link?.linkText}
                                            </Link>
                                        </motion.li>
                                    ))}
                                </ul>
                            </div>
                            <div className="mt-10 h-auto text-base md:mt-0 md:w-1/2">
                                <ul className="list-reset flex flex-col space-y-[27px] leading-normal">
                                    {siteWide.footerNavigation?.map((link, index) => (
                                        <motion.li key={index} {...fadeIn}>
                                            <Link
                                                href={link?.linkUrl!}
                                                className="drawn-underline drawn-underline-bold"
                                            >
                                                {link?.linkText}
                                            </Link>
                                        </motion.li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="mt-16 justify-between lg:flex">
                        <div className="w-full items-center md:flex md:space-x-[90px]">
                            <div className="mb-6 mr-auto h-10 w-[150px] md:mb-0">
                                {techtalentCharity()}
                            </div>
                            <div className="flex items-center space-x-6">
                                <a
                                    href={siteWide?.appStore!}
                                    title="Download OKO app in the App Store"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {appStoreIcon(
                                        'transition hover:scale-105 duration-300 ease-in'
                                    )}
                                </a>

                                <a
                                    href={siteWide?.googlePlayStore!}
                                    title="Download OKO app in Google Play Store"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {playStoreIcon(
                                        'transition hover:scale-105 duration-300 ease-in'
                                    )}
                                </a>
                            </div>
                            <motion.div className="mt-10 flex space-x-6 md:mt-0">
                                {socialLinks.map((social, index) => {
                                    return (
                                        <ButtonSecondary
                                            title={`Go to our ${social.name}`}
                                            key={index}
                                            url={social.url}
                                            classes="h-7 w-7 bg-white rounded-full flex items-center justify-center transition duration-500 hover:bg-opacity-30 text-black hover:text-white"
                                        >
                                            {social.icon}
                                        </ButtonSecondary>
                                    )
                                })}
                            </motion.div>
                        </div>
                    </div>
                </div>
                {siteWide.footerSignature && (
                    <>
                        <hr className="opacity-40" />
                        <div className="mt-8 w-full text-xs text-white opacity-50 md:mt-16">
                            <motion.p {...fadeIn}>{signature}</motion.p>
                        </div>
                    </>
                )}
            </div>
        </footer>
    )
}

export default AppFooter
