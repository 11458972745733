const minifyString = (str: string) => {
    if (str.length !<= 160) str.substring(0, 160) // Set length of string to 160 if more than (meta desc)

    const maxChars = 160

    return str.split(' ').reduce((acc, str) => {
        return acc.length < maxChars ? acc + ' ' + str : acc
    }, '')
}

export default minifyString
