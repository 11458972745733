function twitterIcon() {
    return (
        <svg
            width="16"
            height="13"
            viewBox="0 0 16 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.4686 1.44076C14.8886 1.72892 14.3085 1.82497 13.6317 1.92102C14.3085 1.53682 14.7919 0.96051 14.9852 0.192102C14.4052 0.576306 13.7284 0.768408 12.955 0.96051C12.3749 0.384204 11.5048 0 10.6347 0C8.99114 0 7.54096 1.44076 7.54096 3.16968C7.54096 3.45784 7.54096 3.64994 7.63764 3.84204C5.02731 3.74599 2.61033 2.49733 1.06347 0.576306C0.773432 1.05656 0.676753 1.53682 0.676753 2.20917C0.676753 3.26573 1.25683 4.22624 2.12694 4.80255C1.64354 4.80255 1.16015 4.61045 0.676753 4.41835C0.676753 5.95516 1.74022 7.20382 3.19041 7.49198C2.90037 7.58803 2.61033 7.58803 2.3203 7.58803C2.12694 7.58803 1.93358 7.58803 1.74022 7.49198C2.12694 8.74064 3.28708 9.70115 4.73727 9.70115C3.6738 10.5656 2.3203 11.0459 0.773432 11.0459C0.483395 11.0459 0.290037 11.0459 0 11.0459C1.45018 11.9103 3.09373 12.4866 4.83395 12.4866C10.6347 12.4866 13.8251 7.68408 13.8251 3.55389C13.8251 3.45784 13.8251 3.26573 13.8251 3.16968C14.5018 2.68943 15.0819 2.11312 15.4686 1.44076Z"
                className="fill-current"
            />
        </svg>
    )
}

export default twitterIcon
