function linkedinIcon() {
    return (
        <svg
            width="14"
            height="13"
            viewBox="0 0 14 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.3043 0H0.608696C0.26087 0 0 0.24375 0 0.56875V12.5125C0 12.7562 0.26087 13 0.608696 13H13.3913C13.7391 13 14 12.7563 14 12.4313V0.56875C13.913 0.24375 13.6522 0 13.3043 0ZM4.08696 11.05H2.08696V4.875H4.17391V11.05H4.08696ZM3.13043 4.0625C2.43478 4.0625 1.91304 3.49375 1.91304 2.925C1.91304 2.275 2.43478 1.7875 3.13043 1.7875C3.82609 1.7875 4.34783 2.275 4.34783 2.925C4.26087 3.49375 3.73913 4.0625 3.13043 4.0625ZM11.8261 11.05H9.73913V8.04375C9.73913 7.3125 9.73913 6.41875 8.69565 6.41875C7.65217 6.41875 7.47826 7.23125 7.47826 8.04375V11.1312H5.3913V4.875H7.3913V5.6875C7.65217 5.2 8.34783 4.7125 9.30435 4.7125C11.3913 4.7125 11.7391 6.0125 11.7391 7.6375V11.05H11.8261Z"
                className="fill-current"
            />
        </svg>
    )
}

export default linkedinIcon
