function facebookIcon() {
    return (
        <svg
            width="10"
            height="16"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.92254 15.3682L2.90037 8.64461H0V5.76308H2.90037V3.84205C2.90037 1.24943 4.51637 0 6.84427 0C7.95936 0 8.91773 0.0824792 9.19701 0.119344V2.82878L7.58249 2.82951C6.31645 2.82951 6.07131 3.4272 6.07131 4.30429V5.76308H9.6679L8.70111 8.64461H6.0713V15.3682H2.92254Z"
                className="fill-current"
            />
        </svg>
    )
}

export default facebookIcon
