function techtalentCharityLogo() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 964 255">
            <g>
                <g>
                    <path
                        d="M248.99,.19C248.93,.12,83.22-.02,.24-.02V255.31H249.34c0-84.28-.28-255.05-.35-255.12ZM96,109.69l-53.67,21.12,53.67,21.12v24.13L16.6,140.93v-20.25l79.39-35.13v24.13Zm22.57,81.78h-16.13c0-.91,27.7-120.83,28.21-122.24,5.4-.1,10.76-.14,16.13,.02,.14,.79-27.84,120.58-28.22,122.22Zm113.69-50.54l-79.39,35.13v-24.13l53.67-21.12-53.67-21.12v-24.13l79.39,35.13v20.25Z"
                        fill="#fff"
                    />
                    <g>
                        <path
                            d="M846.9,39.47v42.49h-17.82V.05l50.2,42.65V.05h18.03V81.96s-33.52-28.01-50.42-42.49"
                            fill="#fff"
                        />
                        <polygon
                            points="610.43 17.99 610.43 81.96 592.57 81.96 592.51 17.99 573.42 17.99 573.42 .05 629.55 .05 629.61 17.99 610.43 17.99"
                            fill="#fff"
                        />
                        <path
                            d="M757.21,82.18h-48.63V.06h17.64v63.94h30.99s.09,17.31,0,18.18"
                            fill="#fff"
                        />
                        <path
                            d="M353.88,.05h49.62V18.22l-31.23,.07v13.71h24.51v17.85h-24.51v14.15h31.23v17.95h-49.62V.05"
                            fill="#fff"
                        />
                        <polygon
                            points="324.72 17.94 324.72 81.96 306.83 81.96 306.83 17.94 287.71 17.94 287.71 .05 343.87 .05 343.87 17.94 324.72 17.94"
                            fill="#fff"
                        />
                        <path
                            d="M546.04,.05h17.7V81.96h-17.7s.04-30.78,0-31.7h-32.34v31.7h-17.94V.05h17.94V32.41h32.34s-.08-31.87,0-32.36"
                            fill="#fff"
                        />
                        <polygon
                            points="745.26 115.13 745.26 197.61 722.23 197.61 722.15 115.13 697.53 115.13 697.53 91.99 769.92 91.99 769.99 115.13 745.26 115.13"
                            fill="#fff"
                        />
                        <path
                            d="M468.26,91.99h22.82v105.62h-22.82s.05-39.7,0-40.88h-41.7v40.88h-23.13V91.99h23.13v41.73h41.7s-.11-41.1,0-41.73"
                            fill="#fff"
                        />
                        <path
                            d="M576.88,197.61h24.14l-50.46-105.62-50.46,105.62h24.14l7.81-16.35h37.01l7.81,16.35Zm-35.65-35.55l9.33-19.54,9.33,19.54h-18.67Z"
                            fill="#fff"
                        />
                        <path
                            d="M789.9,91.99h63.99v23.43l-40.27,.09v17.68h31.61v23.02h-31.61v18.25h40.27v23.15h-63.99V91.99"
                            fill="#fff"
                        />
                        <path
                            d="M682.84,81.96h18.72L662.43,.05l-39.13,81.91h18.72l6.06-12.68h28.7l6.06,12.68Zm-27.65-27.57l7.24-15.15,7.24,15.15h-14.47Z"
                            fill="#fff"
                        />
                        <path
                            d="M766.69,.05h49.62V18.22l-31.23,.07v13.71h24.51v17.85h-24.51v14.15h31.23v17.95h-49.62V.05"
                            fill="#fff"
                        />
                        <polygon
                            points="944.54 17.99 944.54 81.96 926.67 81.96 926.61 17.99 907.52 17.99 907.52 .05 963.65 .05 963.71 17.99 944.54 17.99"
                            fill="#fff"
                        />
                        <path
                            d="M698.56,197.61l-33.24-44.6c11.61-4.69,19.81-16.07,19.81-29.36,0-17.49-14.18-31.66-31.66-31.66h-37.65v105.62h23.2v-42.54l31.71,42.54h27.84Zm-59.55-84.4h13.46c5.77,0,10.44,4.67,10.44,10.44s-4.67,10.44-10.44,10.44h-13.46v-20.88Z"
                            fill="#fff"
                        />
                        <path
                            d="M963.06,197.61l-33.24-44.6c11.61-4.69,19.81-16.07,19.81-29.36,0-17.49-14.18-31.66-31.66-31.66h-37.65v105.62h23.2v-42.54l31.71,42.54h27.84Zm-59.55-84.4h13.46c5.77,0,10.44,4.67,10.44,10.44s-4.67,10.44-10.44,10.44h-13.46v-20.88Z"
                            fill="#fff"
                        />
                        <path
                            d="M472.49,53.57c-4.02,6.85-11.22,11.41-19.43,11.41-12.6,0-22.82-10.73-22.82-23.98s10.21-23.98,22.82-23.98c8.22,0,15.41,4.56,19.43,11.41l13.46-11.12C478.58,6.59,466.34-.44,452.48-.44c-22.53,0-40.8,18.56-40.8,41.45s18.27,41.45,40.8,41.45c13.86,0,26.1-7.03,33.47-17.77l-13.46-11.12Z"
                            fill="#fff"
                        />
                        <path
                            d="M369.17,161.13c-5.22,8.9-14.57,14.83-25.25,14.83-16.37,0-29.65-13.95-29.65-31.16s13.27-31.16,29.65-31.16c10.68,0,20.03,5.93,25.25,14.83l17.49-14.45c-9.58-13.95-25.48-23.09-43.5-23.09-29.28,0-53.01,24.12-53.01,53.86s23.74,53.86,53.01,53.86c18.01,0,33.92-9.14,43.5-23.09l-17.49-14.45Z"
                            fill="#fff"
                        />
                    </g>
                </g>
            </g>
            <g id="Layer_5">
                <g>
                    <path
                        d="M291.14,241.22l8.16-.79c.49,2.74,1.49,4.75,2.99,6.03s3.53,1.93,6.07,1.93c2.7,0,4.73-.57,6.1-1.71,1.37-1.14,2.05-2.48,2.05-4.01,0-.98-.29-1.82-.86-2.51-.58-.69-1.58-1.29-3.02-1.8-.98-.34-3.22-.94-6.71-1.81-4.49-1.11-7.65-2.48-9.46-4.11-2.55-2.28-3.82-5.07-3.82-8.35,0-2.11,.6-4.09,1.8-5.93,1.2-1.84,2.93-3.24,5.18-4.21,2.26-.96,4.98-1.44,8.17-1.44,5.21,0,9.13,1.14,11.77,3.43s4.02,5.33,4.15,9.15l-8.38,.37c-.36-2.13-1.13-3.67-2.31-4.6-1.18-.94-2.95-1.4-5.31-1.4s-4.34,.5-5.72,1.5c-.89,.64-1.33,1.5-1.33,2.58,0,.98,.42,1.82,1.25,2.52,1.06,.89,3.62,1.81,7.7,2.78s7.09,1.96,9.05,2.99c1.95,1.03,3.48,2.44,4.59,4.22,1.1,1.78,1.66,3.99,1.66,6.61,0,2.38-.66,4.61-1.98,6.68-1.32,2.08-3.19,3.62-5.61,4.63-2.42,1.01-5.43,1.52-9.03,1.52-5.25,0-9.28-1.21-12.09-3.64-2.81-2.43-4.49-5.96-5.04-10.61Z"
                        fill="#fff"
                    />
                    <path d="M331.69,254.73v-41.52h8.38v41.52h-8.38Z" fill="#fff" />
                    <path
                        d="M367.38,239.46v-7h18.07v16.54c-1.76,1.7-4.3,3.2-7.63,4.49-3.33,1.29-6.71,1.94-10.12,1.94-4.34,0-8.13-.91-11.36-2.73-3.23-1.82-5.65-4.43-7.28-7.82-1.62-3.39-2.44-7.08-2.44-11.06,0-4.32,.91-8.17,2.72-11.53s4.46-5.94,7.96-7.73c2.66-1.38,5.98-2.07,9.94-2.07,5.15,0,9.18,1.08,12.08,3.24,2.9,2.16,4.76,5.15,5.59,8.96l-8.33,1.56c-.59-2.04-1.69-3.65-3.3-4.83-1.61-1.18-3.63-1.77-6.05-1.77-3.66,0-6.58,1.16-8.74,3.48-2.16,2.32-3.24,5.77-3.24,10.34,0,4.93,1.09,8.62,3.29,11.09,2.19,2.46,5.06,3.7,8.61,3.7,1.76,0,3.52-.34,5.28-1.03,1.77-.69,3.28-1.53,4.55-2.51v-5.27h-9.6Z"
                        fill="#fff"
                    />
                    <path
                        d="M393.26,254.73v-41.52h8.16l16.99,27.73v-27.73h7.79v41.52h-8.41l-16.74-27.07v27.07h-7.79Z"
                        fill="#fff"
                    />
                    <path
                        d="M472.5,254.73h-9.12l-3.62-9.43h-16.6l-3.43,9.43h-8.89l16.17-41.52h8.86l16.62,41.52Zm-15.43-16.43l-5.72-15.41-5.61,15.41h11.33Z"
                        fill="#fff"
                    />
                    <path
                        d="M481.99,254.73v-34.49h-12.32v-7.02h32.99v7.02h-12.29v34.49h-8.38Z"
                        fill="#fff"
                    />
                    <path
                        d="M505.33,234.22c0-4.23,.63-7.78,1.9-10.65,.94-2.11,2.23-4.01,3.87-5.69,1.63-1.68,3.42-2.93,5.37-3.74,2.59-1.09,5.57-1.64,8.95-1.64,6.12,0,11.01,1.9,14.68,5.69,3.67,3.79,5.51,9.07,5.51,15.83s-1.82,11.95-5.47,15.73c-3.64,3.79-8.51,5.68-14.61,5.68s-11.08-1.88-14.73-5.65c-3.64-3.77-5.47-8.95-5.47-15.56Zm8.64-.28c0,4.7,1.09,8.26,3.26,10.69,2.17,2.43,4.93,3.64,8.27,3.64s6.08-1.2,8.23-3.61c2.14-2.41,3.21-6.02,3.21-10.83s-1.04-8.31-3.13-10.65c-2.09-2.34-4.86-3.51-8.31-3.51s-6.24,1.18-8.35,3.55c-2.11,2.37-3.17,5.94-3.17,10.72Z"
                        fill="#fff"
                    />
                    <path
                        d="M552.17,254.73v-41.52h17.64c4.44,0,7.66,.37,9.67,1.12,2.01,.75,3.62,2.07,4.83,3.98s1.81,4.09,1.81,6.54c0,3.12-.92,5.69-2.75,7.72-1.83,2.03-4.57,3.31-8.21,3.84,1.81,1.06,3.31,2.22,4.49,3.48,1.18,1.26,2.77,3.51,4.77,6.74l5.07,8.1h-10.03l-6.06-9.03c-2.15-3.23-3.62-5.26-4.42-6.1-.79-.84-1.63-1.42-2.52-1.73-.89-.31-2.29-.47-4.22-.47h-1.7v17.33h-8.38Zm8.38-23.96h6.2c4.02,0,6.53-.17,7.53-.51s1.78-.92,2.35-1.76,.85-1.87,.85-3.12c0-1.4-.37-2.53-1.12-3.38-.75-.86-1.8-1.4-3.16-1.63-.68-.09-2.72-.14-6.12-.14h-6.54v10.54Z"
                        fill="#fff"
                    />
                    <path
                        d="M602.78,254.73v-17.47l-15.21-24.04h9.83l9.77,16.43,9.57-16.43h9.66l-15.26,24.1v17.42h-8.35Z"
                        fill="#fff"
                    />
                </g>
            </g>
        </svg>
    )
}

export default techtalentCharityLogo
