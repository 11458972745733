function defaultCurve(classes?: string) {
  return (
    <svg
      preserveAspectRatio="none"
      className={`w-full h-auto ${classes}`}
      viewBox="0 0 375 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M375 4.82227V0H0V3.19727C67.0579 6.86816 161.824 6.92285 225.058 6.8584C231.338 6.85352 238.396 6.91504 246.014 7H336.622C350.162 6.60938 363.218 5.93457 375 4.82227Z"
      />
    </svg>
  )
}

export default defaultCurve