import * as React from 'react'
import Head from 'next/head'
import minifyString from '@/lib/utils/shorten-string'

import { FragmentImage } from '@/gql/types/FragmentImage'
import { StandardArticleQuery_entry_articles_article_Entry } from '@/gql/types/StandardArticleQuery'
import { SiteWideQuery_globalSet } from '@/gql/types/SiteWideQuery'

type SeoType = {
    data?: StandardArticleQuery_entry_articles_article_Entry
    siteWide: SiteWideQuery_globalSet
    title: string
    description: string
    image: FragmentImage
    canonical: string
}

const Seo: React.FunctionComponent<SeoType> = ({
    data,
    siteWide,
    title,
    description,
    image,
    canonical,
}) => {
    const socialImage = siteWide?.socialImage![0]!
    const img = image?.src! ? image : socialImage

    return (
        <Head>
            <title>{title !== null ? title : data?.title!}</title>
            <meta
                name="description"
                content={description !== null ? description : minifyString(data?.summary!)}
            />
            <link href={`${process.env.NEXT_PUBLIC_SITE_URL}${canonical}`} rel="canonical" />
            <meta name="referrer" content="no-referrer-when-downgrade" />
            <meta content="en-GB" property="og:locale" />
            <link href={process.env.NEXT_PUBLIC_SITE_URL} rel="alternate" hrefLang="x-default" />
            <link href={process.env.NEXT_PUBLIC_SITE_URL} rel="home" />
            <meta content={process.env.NEXT_PUBLIC_SITE_NAME} property="og:site_name" />
            <meta property="og:type" content="website" />
            <meta property="og:image" content={img?.src!} />
            <meta property="og:image:alt" content={img?.alt!} />
            <meta property="og:image:width" content={`${img?.width}`} />
            <meta property="og:image:height" content={`${img?.height}`} />
            <meta name="twitter:title" content={title} />
            <meta
                name="twitter:description"
                content={description !== null ? description : minifyString(data?.summary!)}
            />
            <meta name="twitter:image" content={img?.src!} />
            <meta name="twitter:image:alt" content={img?.alt!} />
            <meta name="twitter:site" content={process.env.NEXT_PUBLIC_TWITTER_HANDLE} />
            <meta name="twitter:creator" content={process.env.NEXT_PUBLIC_TWITTER_HANDLE} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:image:width" content={`${img?.width}`} />
            <meta name="twitter:image:height" content={`${img?.height}`} />
            <link
                type="text/plain"
                href={`${process.env.NEXT_PUBLIC_SITE_URL}/humans.txt`}
                rel="author"
            />
        </Head>
    )
}

export default Seo
