import * as React from 'react'
import Link from 'next/link'

import { FragmentButton } from '@/gql/types/FragmentButton'
import { buttonMapping } from '@/config/ts/tailwind'

type ButtonType = {
    data: FragmentButton
    /* Define as the background 'theme' colour of the parent container */
    theme?: string | null
    classes?: string | null
    transition?: boolean | null
    onClick?: React.MouseEventHandler<HTMLElement>
}

const ButtonPrimary: React.FunctionComponent<ButtonType> = ({
    data,
    theme = 'white',
    classes,
    onClick,
    transition = true,
}) => {
    const [target, setTarget] = React.useState(
        RegExp('^(?:[a-z]+:)?//', 'i').test(data.buttonUrl!) ? '_blank' : undefined
    )

    const [rel, setRel] = React.useState(
        RegExp('^(?:[a-z]+:)?//', 'i').test(data.buttonUrl!) ? 'noopener noreferrer' : undefined
    )

    const [dynamicClass, setDynamicClass] = React.useState(
        theme ? buttonMapping[theme] : buttonMapping['white']
    )

    React.useEffect(() => {
        setDynamicClass(buttonMapping[theme!])
    }, [theme])

    return !data.buttonUrl && data?.linkOrSection! !== null && data.linkOrSection === 'section' ? (
        <div
            role="button"
            onClick={onClick}
            className={`cursor-pointer font-sans font-black ${classes} flex h-[50px] w-full max-w-[266px] content-center justify-center rounded-full border-2 py-2 leading-[30px] sm:max-w-[300px] ${dynamicClass}`}
        >
            {data.buttonTitle}
        </div>
    ) : (
        <a
            href={data.buttonUrl! !== null ? data.buttonUrl! : '/'}
            onClick={onClick}
            className={`cursor-pointer font-sans font-black ${classes} flex h-[50px] w-full max-w-[266px] content-center justify-center rounded-full border-2 py-2 leading-[30px] sm:max-w-[300px] ${dynamicClass}`}
            target={target}
            rel={rel}
        >
            {data.buttonTitle}
        </a>
    )
}

export default ButtonPrimary
