function okoLogo() {
    return (
        <svg width="73" height="41" viewBox="0 0 73 41" fill="inherit" xmlns="http://www.w3.org/2000/svg">
            <path d="M50.9237 6.38488C49.9224 5.34083 48.4662 4.98571 46.6126 5.32663C44.9578 5.63204 43.6581 6.56954 41.648 8.91329C40.7461 9.96444 39.901 11.0724 39.0984 12.2443C39.0984 10.6747 39.0984 9.18319 39.1126 7.75563V7.68458C39.1267 5.5752 39.1481 3.57947 37.7632 1.87491C36.9534 0.873484 35.9663 0.248483 34.8939 0.063822C34.837 0.0567197 34.7801 0.0425151 34.7235 0.035413C32.9124 -0.206065 31.5273 0.852179 31.0088 1.24991C27.7135 3.76413 27.1452 11.2287 27.053 14.3181C26.9109 19.169 27.5642 25.2059 29.3398 27.7416C29.6523 28.189 30.4763 29.3679 31.996 29.8153C34.0131 30.4048 35.7817 29.24 36.1155 28.9985C37.3655 28.1038 37.8412 26.9035 38.452 25.3766C38.5089 25.2345 38.5586 25.0993 38.6155 24.9575C39.8299 27.0597 41.8684 29.5242 44.8584 29.8083C45.9805 29.9149 47.2518 29.7087 48.3455 29.2472C49.6879 28.6791 50.5119 27.8124 50.6609 26.8183C50.8599 25.4829 49.8441 24.4177 48.5659 23.0682C47.9337 22.4077 47.4932 22.0881 47.1311 21.8395C46.8399 21.6335 46.6623 21.5128 46.4281 21.2216C45.9735 20.6605 45.2489 19.4034 45.3982 18.2883C45.5189 17.4076 46.9607 16.0866 49.134 14.0838C49.354 13.8778 49.5602 13.7003 49.7448 13.5298C50.9451 12.4502 51.6625 11.7897 51.9464 10.5113C52.2234 9.31102 52.0885 7.59936 50.9237 6.38488Z" />
            <path d="M40.8171 32.8906C33.5798 36.2075 27.7986 31.0653 27.557 30.8452L27.5714 30.8593C27.5714 30.8593 25.3198 28.9417 23.388 30.9446C21.4562 32.9474 23.4804 35.2487 23.4804 35.2487C23.7076 35.4616 25.8241 37.3792 29.2332 38.7144C31.0229 39.4176 32.8482 39.8367 34.6665 39.9646C37.5925 40.1706 40.5187 39.6308 43.317 38.3453C44.8228 37.6562 45.4833 35.8736 44.7943 34.3678C44.1054 32.855 42.3229 32.1945 40.8171 32.8906Z" />
            <path d="M68.9565 12.4289C65.9309 9.43884 62.0247 9.48147 61.6198 9.49568C60.8811 9.51697 58.0614 9.63771 55.6184 11.7755C53.5444 13.5866 52.9125 15.7954 52.4721 17.5568C51.7547 20.4048 51.8329 23.4588 52.7135 26.2571C53.1467 27.642 53.7859 29.1903 55.2703 30.4688C58.2035 32.9971 62.7632 32.7273 65.9026 31.1293C69.7235 29.1833 71.0374 25.4617 71.3499 24.5384C72.5715 20.9091 72.3514 15.7812 68.9565 12.4289ZM63.7434 21.5909C63.6866 21.7613 63.4379 22.4503 62.7349 22.8125C62.1524 23.1108 61.3073 23.1605 60.7604 22.6917C60.4834 22.4574 60.3699 22.1662 60.2847 21.9105C60.1212 21.392 60.107 20.8238 60.2419 20.2983C60.3271 19.9716 60.4409 19.5596 60.8244 19.2258C61.2788 18.8281 61.7974 18.8068 61.9393 18.7997C62.0175 18.7997 62.7419 18.7926 63.303 19.3466C63.928 19.9645 63.9707 20.9162 63.7434 21.5909Z" />
            <path d="M21.4846 9.15479C17.6565 5.36925 12.7133 5.42606 12.209 5.44027C11.2715 5.46869 7.70615 5.61783 4.62377 8.3167C2.00301 10.6107 1.20045 13.4091 0.639372 15.6321C-0.262621 19.2401 -0.170291 23.0966 0.944771 26.6407C1.49875 28.3877 2.30131 30.3481 4.17632 31.9673C7.88371 35.1635 13.6508 34.8224 17.621 32.7984C22.4506 30.3338 24.1195 25.6322 24.5172 24.4602C26.0586 19.8793 25.7744 13.3949 21.4846 9.15479ZM15.9519 21.1435C15.8454 21.456 15.398 22.7273 14.0911 23.3949C13.0187 23.9418 11.4633 24.0342 10.4619 23.1676C9.9576 22.7273 9.73741 22.2017 9.58827 21.7258C9.28998 20.7671 9.26157 19.7301 9.50304 18.7571C9.65218 18.1534 9.87237 17.4005 10.5755 16.7827C11.4065 16.0511 12.3724 16.0156 12.6209 16.0085C12.7559 16.0014 14.0911 15.9872 15.1281 17.0099C16.2928 18.1534 16.371 19.9077 15.9519 21.1435Z" />
        </svg>
    )
}

export default okoLogo
